define("discourse/plugins/discourse-epic-games/discourse/components/new-topic-dropdown", ["exports", "@glimmer/component", "@ember/helper", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse-i18n", "select-kit/components/dropdown-select-box", "discourse/plugins/discourse-epic-games/discourse/lib/open-composer", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _object, _service, _dButton, _discourseI18n, _dropdownSelectBox, _openComposer, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class NewTopicDropdown extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    options = {
      icon: "plus",
      showFullTitle: true,
      autoFilterable: false,
      filterable: false,
      showCaret: true,
      none: "topic.create"
    };
    get hasFormTemplate() {
      return this.args.category?.form_template_ids?.length > 0;
    }
    get content() {
      const items1 = [{
        id: "new_discussion",
        name: _discourseI18n.default.t("discourse_epic_games.new_topic_button"),
        description: _discourseI18n.default.t("discourse_epic_games.new_topic_description"),
        icon: "comment"
      }];
      items1.push({
        id: "new_question",
        name: _discourseI18n.default.t("discourse_epic_games.question_button"),
        description: _discourseI18n.default.t("discourse_epic_games.question_description"),
        icon: "question-circle"
      });
      if (this.site.globalFilter === "epic-games-store") {
        items1.push({
          id: "new_private_question",
          name: _discourseI18n.default.t("discourse_epic_games.private_question.label"),
          description: _discourseI18n.default.t("discourse_epic_games.private_question.description"),
          icon: "lock"
        });
      }
      return items1;
    }
    onChange(selectedAction1) {
      let refid1 = "";
      if (selectedAction1 === "new_issue_report") {
        const urlParams1 = new URLSearchParams(window.location.search);
        refid1 = urlParams1.get("refid");
      }
      const opts1 = {
        selectedAction: selectedAction1,
        categoryId: this.args.category?.id,
        hasFormTemplate: this.hasFormTemplate,
        refid: refid1
      };
      (0, _openComposer.openComposer)(opts1);
    }
    static #_2 = (() => dt7948.n(this.prototype, "onChange", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.hasFormTemplate}}
          <DButton
            @label="discourse_epic_games.new_issue_button"
            id="new_issue_report"
            @icon="exclamation-circle"
            @action={{fn this.onChange "new_issue_report"}}
            class="btn-primary"
          />
        {{else}}
          <DropdownSelectBox
            @content={{this.content}}
            @onChange={{this.onChange}}
            @options={{this.options}}
            class="new-topic-dropdown"
            @id="epic-new-topic-dropdown"
          />
        {{/if}}
      
    */
    {
      "id": "gS8vzqaB",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"hasFormTemplate\"]],[[[1,\"      \"],[8,[32,0],[[24,1,\"new_issue_report\"],[24,0,\"btn-primary\"]],[[\"@label\",\"@icon\",\"@action\"],[\"discourse_epic_games.new_issue_button\",\"exclamation-circle\",[28,[32,1],[[30,0,[\"onChange\"]],\"new_issue_report\"],null]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[32,2],[[24,0,\"new-topic-dropdown\"]],[[\"@content\",\"@onChange\",\"@options\",\"@id\"],[[30,0,[\"content\"]],[30,0,[\"onChange\"]],[30,0,[\"options\"]],\"epic-new-topic-dropdown\"]],null],[1,\"\\n\"]],[]]],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/components/new-topic-dropdown.js",
      "scope": () => [_dButton.default, _helper.fn, _dropdownSelectBox.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = NewTopicDropdown;
});