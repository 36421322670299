define("discourse/plugins/discourse-epic-games/discourse/initializers/digital-consent-guards", ["exports", "@ember/object", "@ember/template", "discourse/lib/plugin-api", "discourse-i18n", "discourse/plugins/discourse-epic-games/discourse/lib/digital-consent"], function (_exports, _object, _template, _pluginApi, _discourseI18n, _digitalConsent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "epic-digital-consent-guards",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      if (!siteSettings.epic_digital_consent_cta_enabled) {
        return;
      }
      const currentUser = container.lookup("service:current-user");
      const router = container.lookup("service:router");
      const toasts = container.lookup("service:toasts");

      // Add a toast to the top of the page to inform the user that they need to provide consent
      if ((0, _digitalConsent.hasDigitalConsentPendingGroup)(currentUser)) {
        document.body.classList.add("consent-to-communicate-pending");
        toasts.warning({
          autoClose: false,
          class: "digital-consent-toast",
          data: {
            title: _discourseI18n.default.t("discourse_epic_games.digital_consent_toast.title"),
            message: (0, _template.htmlSafe)(_discourseI18n.default.t("discourse_epic_games.digital_consent_toast.message", {
              digital_consent_url: `${siteSettings.epic_external_digital_consent_url}${currentUser.id}`
            })),
            icon: "exclamation-triangle"
          }
        });
      }
      (0, _pluginApi.withPluginApi)("0.8.14", api => {
        api.modifyClass("controller:topic", Superclass => class extends Superclass {
          replyToPost() {
            // don't let the user reply if they are in DIGITAL_CONSENT_PENDING_GROUP
            if ((0, _digitalConsent.default)(currentUser, toasts, siteSettings.epic_external_digital_consent_url)) {
              return;
            }
            super.replyToPost(...arguments);
          }
          static #_ = (() => dt7948.n(this.prototype, "replyToPost", [_object.action]))();
          replyAsNewTopic() {
            // don't let the user reply if they are in DIGITAL_CONSENT_PENDING_GROUP
            if ((0, _digitalConsent.default)(currentUser, toasts, siteSettings.epic_external_digital_consent_url)) {
              return;
            }
            super.replyAsNewTopic(...arguments);
          }
          static #_2 = (() => dt7948.n(this.prototype, "replyAsNewTopic", [_object.action]))();
        });
        api.modifyClass("component:bookmark-menu", Superclass => class extends Superclass {
          onShowMenu() {
            // don't let the user create a bookmark if they are in DIGITAL_CONSENT_PENDING_GROUP
            if ((0, _digitalConsent.default)(currentUser, toasts, siteSettings.epic_external_digital_consent_url)) {
              return false;
            }
            super.onShowMenu(...arguments);
          }
          static #_ = (() => dt7948.n(this.prototype, "onShowMenu", [_object.action]))();
        });
        api.modifyClass("component:modal/share-topic", Superclass => class extends Superclass {
          inviteUsers() {
            // don't let the user create an invite if they are in DIGITAL_CONSENT_PENDING_GROUP
            if ((0, _digitalConsent.default)(currentUser, toasts, siteSettings.epic_external_digital_consent_url)) {
              return false;
            }
            super.inviteUsers(...arguments);
          }
          static #_ = (() => dt7948.n(this.prototype, "inviteUsers", [_object.action]))();
        });
        api.modifyClass("component:invite-panel", Superclass => class extends Superclass {
          createInvite() {
            // don't let the user create a invite if they are in DIGITAL_CONSENT_PENDING_GROUP
            if ((0, _digitalConsent.default)(currentUser, toasts, siteSettings.epic_external_digital_consent_url)) {
              return false;
            }
            super.createInvite(...arguments);
          }
          static #_ = (() => dt7948.n(this.prototype, "createInvite", [_object.action]))();
        });
        api.modifyClass("controller:user-invited-show", Superclass => class extends Superclass {
          createInvite() {
            // don't let the user create an invite if they are in DIGITAL_CONSENT_PENDING_GROUP
            if ((0, _digitalConsent.default)(currentUser, toasts, siteSettings.epic_external_digital_consent_url)) {
              return;
            }
            super.createInvite(...arguments);
          }
          static #_ = (() => dt7948.n(this.prototype, "createInvite", [_object.action]))();
        });
        api.reopenWidget("post-menu", {
          like() {
            // don't let the user like a post if they are in DIGITAL_CONSENT_PENDING_GROUP
            if ((0, _digitalConsent.default)(currentUser, toasts, siteSettings.epic_external_digital_consent_url)) {
              return;
            }
            this._super(...arguments);
          }
        });
        api.registerBehaviorTransformer("topic-vote-button-click", _ref => {
          let {
            next
          } = _ref;
          // don't let the user vote if they are in DIGITAL_CONSENT_PENDING_GROUP
          if ((0, _digitalConsent.default)(currentUser, toasts, siteSettings.epic_external_digital_consent_url)) {
            return;
          }
          next();
        });
        api.modifyClass("route:application", Superclass => class extends Superclass {
          composePrivateMessage() {
            // don't let the user create a private message if they are in DIGITAL_CONSENT_PENDING_GROUP
            if ((0, _digitalConsent.default)(currentUser, toasts, siteSettings.epic_external_digital_consent_url)) {
              return;
            }
            super.composePrivateMessage(...arguments);
          }
          static #_ = (() => dt7948.n(this.prototype, "composePrivateMessage", [_object.action]))();
        });
        api.modifyClass("controller:preferences/profile", Superclass => class extends Superclass {
          showFeaturedTopicModal() {
            // don't let the user update their featured topic if they are in DIGITAL_CONSENT_PENDING_GROUP
            if ((0, _digitalConsent.default)(currentUser, toasts, siteSettings.epic_external_digital_consent_url)) {
              return;
            }
            super.showFeaturedTopicModal(...arguments);
          }
          static #_ = (() => dt7948.n(this.prototype, "showFeaturedTopicModal", [_object.action]))();
        });
        router.on("routeWillChange", transition => {
          // don't let the user create a new topic / message if they are in DIGITAL_CONSENT_PENDING_GROUP
          if ((transition.targetName === "new-topic" || transition.targetName === "new-message") && (0, _digitalConsent.hasDigitalConsentPendingGroup)(currentUser)) {
            return router.transitionTo("/404");
          }
        });
      });
    }
  };
});